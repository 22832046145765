import { Button as MaterialButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ExportButton } from '../components/common';
import BaseLayout from '../components/layout/base-layout';
import FoundersTable from '../components/tables/founders-table';
import { UserContext } from '../contexts/user-context';
import { Pages } from '../router/constants';
import { ProtectedRouteProps } from '../router/type';
import { isTablet } from '../utils/functions';
import { CLASS_TRACKING } from '../utils/tracking_class';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 25,
    padding: '0 20px !important',

    [theme.breakpoints.up('xs')]: {
      padding: 0,
    },
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',

    '& > * + *': {
      marginLeft: 15,
    },
  },
}));

function FoundersPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { hasAccessToAction } = useContext(UserContext);

  return (
    <BaseLayout user={user} fullWidth>
      <div className={classes.header}>
        <Typography variant='h2'>Founders</Typography>
        <div className={classes.headerActions}>
          {hasAccessToAction('founder.create') && (
            <MaterialButton
              className={CLASS_TRACKING.ENTITY_ACTION}
              component={Link}
              to={Pages.NEW_FOUNDER}
              data-testid='button-new-founder'
              startIcon={<AddIcon />}
              variant='contained'
              color='primary'>
              Founder
            </MaterialButton>
          )}
          {!isTablet() && hasAccessToAction('founder.export') && (
            <ExportButton alias='founder' />
          )}
        </div>
      </div>
      <div>
        <FoundersTable />
      </div>
    </BaseLayout>
  );
}

export default FoundersPage;
