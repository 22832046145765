import decodeJWTToken from 'jwt-decode';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import _get from 'lodash/get';
import authAPI, { EventChannel, Tenant } from '../api/auth';
import * as tenantsAPI from '../api/tenants';
import { TenantId } from '../api/tenants/Tenant';
import { IdentityId } from '../api/user/IdentityId';
import { JWTData } from '../api/user/JWTData';
import { JWTToken } from '../api/user/JWTToken';
import { Module } from '../api/user/Module';
import { Role } from '../api/user/Role';
import { useLocalStorageState } from '../hooks/useLocalStorageState';
import { Email } from '../utils/String/Email';
import { ADMIN_RULES, FOUNDER_RULES, MENTOR_RULES } from '../utils/rules-const';

export interface UserProps {
  email: string;
  name: string;
}
export interface UserContextProps {
  user?: Tenant;
  channels?: EventChannel[];
  isUserLoading: boolean;
  token: undefined | JWTToken;
  tokenData: undefined | JWTData;
  logo: string | undefined;
  setLogo: Dispatch<SetStateAction<string | undefined>>;
  loadUser: () => Promise<void>;
  updateToken: (nextToken: JWTToken | null, redirect: boolean) => void;
  updateUser: (userDetails: Tenant) => any;
  updateChannels: (channels: EventChannel[]) => any;
  hasAccessToAction: (rule: string) => boolean;
  hasAccessToModal: (modal: Module) => boolean;
  saveLastVisitedPath: () => void;
  currentEmail?: string;
  hasModules: boolean;
  identityid?: string;
  hasRole: (role: Role) => boolean;
}

export const UserContext = createContext<UserContextProps>({
  isUserLoading: false,
  token: undefined,
  tokenData: undefined,
  logo: undefined,
  setLogo: () => {},
  loadUser: async () => {},
  updateToken: () => {},
  updateUser: () => {},
  updateChannels: () => {},
  hasAccessToAction: () => false,
  hasAccessToModal: () => false,
  saveLastVisitedPath: () => {},
  currentEmail: '',
  hasModules: false,
  hasRole: () => false,
});

interface UserProviderProps {
  children: React.ReactNode;
}

export function decodeToken(token: JWTToken): JWTData | null {
  const data = decodeJWTToken<{ [k: string]: string }>(token);

  return {
    modules: data.modules.split(',').map((module) => module.trim() as Module),
    role: data.role as Role,
    email: data.email as Email,
    identityid: data.identityid as IdentityId,
    tenantId: data.tenantid as TenantId,
    id: data.id,
    sub: data.sub,
  };
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<Tenant>();
  const [logo, setLogo] = useState<string>();
  const [channels, setChannels] = useState<EventChannel[]>();
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [loadError, setLoadError] = useState<string>();
  const [currentEmail, setCurrentEmail] = useState<string>();
  const [decodedToken, setDecodedToken] = useState(() => {
    const token = (localStorage.getItem('token') ?? undefined) as
      | JWTToken
      | undefined;
    const decoded = token ? decodeToken(token) : undefined;

    return decoded
      ? {
          token: token as JWTToken,
          data: decoded,
        }
      : undefined;
  });
  const [lastVisitedPath, setLastVisitedPath] = useLocalStorageState<
    string | null
  >('lastVisitedPath', null);
  const [isPreventSaveLastVisitedPath, setIsPreventSaveLastVisitedPath] =
    useState(false);
  const currentRole = useMemo<Role | undefined>(
    () => decodedToken?.data.role,
    [decodedToken],
  );
  const identityid = decodedToken?.data.identityid;

  const preventSaveLastVisitedPathTimeout = useRef<NodeJS.Timeout | null>(null);

  const modules = useMemo(
    () => decodedToken?.data.modules ?? [],
    [decodedToken?.data.modules],
  );

  const rules = useMemo((): Record<string, unknown> => {
    switch (currentRole) {
      case Role.Admin:
      case Role.Manager:
        return ADMIN_RULES;
      case Role.Mentor:
        return MENTOR_RULES;
      case Role.Founder:
        return FOUNDER_RULES;
      case Role.CommunityMember:
      case undefined:
        return {};
    }
  }, [currentRole]);

  const hasModules = useMemo(
    () => (modules && modules.length > 0) || false,
    [modules],
  );

  const hasRole = (role: Role) => currentRole === role;

  const hasAccessToModal = (modal: Module): boolean => {
    return modules?.includes(modal) || false;
  };

  const hasAccessToAction = (rule: string): boolean => {
    return !!_get(rules, rule) || false;
  };

  const preventSaveLastVisitedPath = useCallback(() => {
    setIsPreventSaveLastVisitedPath(true);

    preventSaveLastVisitedPathTimeout.current = setTimeout(() => {
      setIsPreventSaveLastVisitedPath(false);
    }, 1000);
  }, []);

  const loadUser = useCallback(async () => {
    if (!isUserLoading && !loadError) {
      setIsUserLoading(true);
      try {
        const [loadedUser, loadedChannels, logo] = await Promise.all([
          authAPI.getTenant(),
          authAPI.getChannels(),
          decodedToken?.data.tenantId
            ? tenantsAPI.getLogo(decodedToken?.data.tenantId)
            : Promise.resolve(''),
        ]);
        setUser(loadedUser);
        setLogo(logo);
        setChannels(loadedChannels);
        setIsUserLoading(false);
      } catch (e: any) {
        setLoadError('Internal server error');
        setIsUserLoading(false);
      }
    }
  }, [decodedToken?.data.tenantId, isUserLoading, loadError]);

  const updateToken = useCallback(
    (nextToken: JWTToken | null, redirect: boolean) => {
      if (nextToken) {
        localStorage.setItem('token', nextToken);
        const decoded = decodeToken(nextToken);

        setDecodedToken(
          decoded ? { token: nextToken, data: decoded } : undefined,
        );

        if (lastVisitedPath) {
          window.location.replace(lastVisitedPath);
          setLastVisitedPath(null);
        }
      } else {
        localStorage.removeItem('token');
        setDecodedToken(undefined);
        preventSaveLastVisitedPath();

        if (redirect) {
          window.location.replace('/');
        }
      }
    },
    [lastVisitedPath, preventSaveLastVisitedPath, setLastVisitedPath],
  );

  const updateUser = useCallback((userDetails: Tenant) => {
    setUser(userDetails);
  }, []);

  const updateChannels = useCallback((channels: EventChannel[]) => {
    setChannels(channels);
  }, []);

  const saveLastVisitedPath = useCallback(() => {
    if (!window.location.pathname || isPreventSaveLastVisitedPath) {
      return;
    }

    setLastVisitedPath(window.location.pathname + window.location.search);
  }, [setLastVisitedPath, isPreventSaveLastVisitedPath]);

  useEffect(() => {
    function handleLogoutEvent() {
      localStorage.removeItem('token');
      setDecodedToken(undefined);
      setUser(undefined);
      setChannels(undefined);
      setLoadError(undefined);
      setCurrentEmail('');
    }

    window.addEventListener('traction5.token_expired', handleLogoutEvent);

    return () => {
      if (preventSaveLastVisitedPathTimeout.current) {
        clearTimeout(preventSaveLastVisitedPathTimeout.current);
      }
      window.removeEventListener('traction5.token_expired', handleLogoutEvent);
    };
  }, []);

  useEffect(() => {
    if (decodedToken) {
      setCurrentEmail(decodedToken.data.email);

      // @ts-expect-error
      window.smartlook?.('identify', decodedToken.data.email);
    } else {
      setCurrentEmail('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decodedToken]);

  const contextValue: UserContextProps = {
    user,
    channels,
    isUserLoading,
    logo,
    setLogo,
    loadUser,
    token: decodedToken?.token,
    tokenData: decodedToken?.data,
    updateToken,
    updateUser,
    updateChannels,
    hasAccessToAction,
    currentEmail,
    hasAccessToModal,
    hasModules,
    identityid,
    hasRole,
    saveLastVisitedPath,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
