import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

interface Props {
  parentPath: string | undefined;
  prevPath: string | undefined;
}

export const RouterContext = createContext<Props>({
  parentPath: undefined,
  prevPath: undefined,
});

export const RouterProvider = ({ children }: PropsWithChildren<{}>) => {
  const history = useHistory();

  const [parentPath, setParentPath] = useState<string | undefined>();
  const [prevPath, setPrevPath] = useState<string | undefined>();

  useEffect(() => {
    let lastPath = `${history.location.pathname}${history.location.search}`;
    let lastPathname = history.location.pathname;
    let lastSearch = history.location.search;

    const unlisten = history.listen((location) => {
      setPrevPath(lastPath);
      setParentPath((prev) => {
        const prevParentPathname = prev?.split('?')[0];

        return (prevParentPathname &&
          lastPathname.startsWith(prevParentPathname) &&
          lastPathname.length > prevParentPathname.length) ||
          lastPathname.includes('/new')
          ? prev
          : `${lastPathname}${lastSearch}`;
      });
      lastPath = `${location.pathname}${location.search}`;
      lastPathname = location.pathname;
      lastSearch = location.search;
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <RouterContext.Provider value={{ parentPath, prevPath }}>
      {children}
    </RouterContext.Provider>
  );
};
