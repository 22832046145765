import {
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import PrintIcon from '@material-ui/icons/Print';
import classNames from 'classnames';
import { ReactElement, useContext, useMemo } from 'react';
import { Listing } from '../../components/Pages/Listing';
import {
  DateRangePickerInput,
  FormButtons,
  FormGroup,
} from '../../components/common';
import Button from '../../components/common/button';
import Dialog from '../../components/common/dialog';
import Text from '../../components/common/text';
import BaseLayout from '../../components/layout/base-layout';
import { UserContext } from '../../contexts/user-context';
import { ProtectedRouteProps } from '../../router/type';
import { Report } from './Report';
import * as Actions from './types/Actions';
import { useOfficeHoursReports } from './useOfficeHoursReports';

const useStyles = makeStyles(() => ({
  card: {
    flexGrow: 1,
    display: 'flex',

    '@media print': {
      border: 'none',
      boxShadow: 'none',
      background: 'none',
      padding: '0',
    },
  },
  centered: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    textAlign: 'center',
    width: 500,
  },
}));

export function OfficeHoursReports({
  user,
}: ProtectedRouteProps): ReactElement {
  const [s, dispatch] = useOfficeHoursReports(user);
  const { logo } = useContext(UserContext);

  const actions = [
    <Button
      onClick={window.print}
      key={1}
      variant={'outlined'}
      startIcon={<PrintIcon />}>
      Print
    </Button>,
    <Button
      key={2}
      variant={'contained'}
      onClick={() => dispatch(Actions.customize())}>
      Customize Report
    </Button>,
  ];

  const isOpen = useMemo((): boolean => {
    switch (s.type) {
      case 'Loading':
      case 'LoadError':
      case 'Ready':
        return false;
      case 'CustomizeEmpty':
      case 'CustomizeReady':
        return true;
    }
  }, [s.type]);

  const Content = (): ReactElement => {
    switch (s.type) {
      case 'Loading':
        return <CircularProgress size={54} color='primary' />;
      case 'LoadError':
      case 'CustomizeEmpty':
        return (
          <Text className={classes.error} variant={'normal'}>
            Unfortunately we were not able to extract analytical data for the
            dates you selected. Please get in touch with program admin or
            support@tractionfive.com if you believe this is an unexpected
            result.
          </Text>
        );
      case 'Ready':
      case 'CustomizeReady':
        return (
          <Report
            programName={s.payload.tenant.programName}
            start={s.payload.start}
            end={s.payload.end}
            logo={s.payload.includeLogo ? logo : undefined}
            summary={
              s.payload.includeSummary
                ? {
                    officeHours: s.payload.officeHours,
                    sessions: s.payload.sessions,
                    feedbackForms: s.payload.feedbackForms,
                  }
                : undefined
            }
            advisors={s.payload.advisors}
          />
        );
    }
  };

  const classes = useStyles();

  return (
    <BaseLayout user={user} sidebar={'officehours'} fullHeight>
      <Listing title={'Office Hours report'} actions={actions}>
        <Card className={classes.card}>
          <CardContent className={classNames(classes.card, classes.centered)}>
            <Content />
          </CardContent>
          <Dialog
            title={'Customize report'}
            width={600}
            contentRenderer={() => (
              <>
                <FormGroup>
                  <DateRangePickerInput
                    onChange={(v) =>
                      v?.startDate &&
                      v?.endDate &&
                      dispatch(
                        Actions.setDateRange({
                          start: v.startDate,
                          end: v.endDate,
                        }),
                      )
                    }
                    value={{
                      startDate: s.payload.start,
                      endDate: s.payload.end,
                    }}
                  />
                </FormGroup>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={s.payload.includeLogo}
                        onChange={(e) =>
                          dispatch(Actions.setLogo(e.target.checked))
                        }
                      />
                    }
                    label='Include logo'
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={s.payload.includeSummary}
                        onChange={(e) =>
                          dispatch(Actions.setSummary(e.target.checked))
                        }
                      />
                    }
                    label='Include summary'
                  />
                </div>
                <FormButtons justify={'right'}>
                  <Button
                    variant={'contained'}
                    onClick={() => dispatch(Actions.update())}>
                    Generate
                  </Button>
                  <Button
                    variant={'outlined'}
                    onClick={() => dispatch(Actions.cancelCustomization())}>
                    Cancel
                  </Button>
                </FormButtons>
              </>
            )}
            open={isOpen}
            setOpen={() => dispatch(Actions.cancelCustomization())}
          />
        </Card>
      </Listing>
    </BaseLayout>
  );
}
