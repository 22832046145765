import { Button as MaterialButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Role } from '../api/user/Role';
import { Listing } from '../components/Pages/Listing';
import { ExportButton } from '../components/common';
import BaseLayout from '../components/layout/base-layout';
import MentorsTable from '../components/tables/mentors-table';
import { useResourceBundles } from '../contexts/resource-bundles-context';
import { UserContext } from '../contexts/user-context';
import { Pages } from '../router/constants';
import { ProtectedRouteProps } from '../router/type';
import { isTablet } from '../utils/functions';
import { CLASS_TRACKING } from '../utils/tracking_class';
import MyMentorsPage from './my-mentors';

function MentorsPage({ user }: ProtectedRouteProps) {
  const { hasAccessToAction, hasRole } = useContext(UserContext);
  const { rb } = useResourceBundles();

  return hasRole(Role.Founder) ? (
    <MyMentorsPage user={user} />
  ) : (
    <BaseLayout user={user} fullWidth>
      <Listing
        title={rb('mentors-u')}
        actions={[
          hasAccessToAction('mentor.create') ? (
            <MaterialButton
              className={CLASS_TRACKING.ENTITY_ACTION}
              data-testid='button-new-mentor'
              component={Link}
              to={Pages.NEW_MENTOR}
              startIcon={<AddIcon />}
              variant='contained'
              color='primary'>
              {rb('mentor-u')}
            </MaterialButton>
          ) : null,
          !isTablet() && hasAccessToAction('mentor.export') ? (
            <ExportButton alias='mentor' />
          ) : null,
        ]}>
        <MentorsTable />
      </Listing>
    </BaseLayout>
  );
}

export default MentorsPage;
